import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/indexNew.vue'
import OfficiaWeb from '../views/official_website_index.vue'
import FenghuabghuVr from '../views/fenghuanghu_vr.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/offciaWeb',
    name: 'index',
    component: OfficiaWeb
  },
  {
    path: '/FenghuabghuVr',
    name: 'index',
    component: FenghuabghuVr
  }
]

const router = new VueRouter({
  routes
})

export default router
