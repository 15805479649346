<template>
  <div>
    <!-- <div class="flex backBtn" @click="goback">
      <img class="img" src="../../static/img/icon_map_left_arrow.png" alt="">
      返回</div> -->
    <iframe :src="'https://fhh-map.q12301.com?scenic_id=' + scenic_id + '&points=' + points" class="iframe"></iframe>
  </div>
</template>
<script>

export default {
  name: 'mapImg',
  data() {
    return {
      scenic_id: '',
      points: ''
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    }
  },
  mounted () {
   console.log(this.$route.query.scenic_id);
   this.scenic_id = this.$route.query.scenic_id
   this.points = this.$route.query.points
  }
}
</script>
<style>
.iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.backBtn {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 30px;
}
.img {
  height: 35px;
  margin-right: 10px;
}
</style>