export default {
    getHistoryStorage(historyKey) {
        let s
        if (localStorage.getItem(historyKey) && JSON.parse(localStorage.getItem(historyKey)).constructor == Array) {
            s = JSON.parse(localStorage.getItem(historyKey))
        } else  {
            s = []
            this.clearStorage(historyKey)
        }
        return s
    },
    pushStorage(historyKey, value) {
        let storage = this.getHistoryStorage(historyKey)
        storage.push(value)
        if (storage.length > 6) {
            storage.splice(0, 1)
        }
        localStorage.setItem(historyKey, JSON.stringify(storage))
    },
    clearStorage(historyKey) {
        localStorage.setItem(historyKey, [])
    }
}