import request from "@/utils/request";
var fhhUrl = 'https://fhh-gw.q12301.com/'
export const register = (data) => {
	return request({
		url: "/users/register",
		method: "POST",
		data,
	});
};
export const getAreaInfo = (data) => {
	return request({
		url: "/service/get-area-info-list-api/",
		method: "GET",
		params: data,
	});
};
export const getWeather = (data) => {
	return request({
		url: "/service/scenic_rating_weather_api/",
		method: "GET",
		params: data,
	});
};
export const searchRouteList = (data) => {
	return request({
		url: "/service/route-compute/",
		method: "GET",
		params: data,
	});
};
export const getMapPointList = (data) => {
	return request({
		url: "/service/xcx-get-scenic-point-api/",
		method: "GET",
		params: data,
	});
};
export const addUserRouter = (data) => {
	return request({
		url: "/service/xcx-add-user-router-api/",
		method: "POST",
		data,
	});
};
export const getPointReview = (data) => {
	return request({
		url: "/service/mini-scenic-review-list-api/",
		method: "GET",
		params: data,
	});
};
export const getScenicIndex = (data) => {
	return request({
		url: "/service/mini-scenic-index-data-api/",
		method: "GET",
		params: data,
	});
};
export const getKeywordList = (data) => {
	return request({
		url: "/service/xcx-keyword-info-list/",
		method: "GET",
		params: data,
	});
};
export const getRouterList = (data) => {
	return request({
		url: "/service/xcx-get-router-api/",
		method: "GET",
		params: data,
	});
};
export const delUserRouter = (data) => {
	return request({
		url: "/service/xcx-del-user-router-api/",
		method: "POST",
		data,
	});
};
export const getPointDetail = (data) => {
	return request({
		url: "/service/xcx-get-point-detail-api/",
		method: "GET",
		params: data,
	});
};
export const getAddressApi = (data) => {
	return request({
		url: "/service/get-area-info-list-api/",
		method: "GET",
		params: data,
	});
};
// 获取手绘地图
export const getHandDrawnMap = (data) => {
	return request({
		url: "/service/get-hand-drawn-map-info-list-api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖官网首页
export const getOfficialWebsite = (data) => {
	return request({
		url: fhhUrl + "/home_api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖景区概况
export const getFffhScenicSpotOverview = (data) => {
	return request({
		url: fhhUrl + "/overview_api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖景区动态
export const getFffScenicSpot = (data) => {
	return request({
		url: fhhUrl + "/scenic_info_api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖文章详情
export const getFffhome_content = (data) => {
	return request({
		url: fhhUrl + "/home_content_api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖景区动态详情
export const getActiveInfo = (data) => {
	return request({
		url: fhhUrl + "/scenic_info_content_api/",
		method: "GET",
		params: data,
	});
};
// 凤凰湖景区玩转景区
export const play_scenic_Info = (data) => {
	return request({
		url: fhhUrl + "/play_scenic_api/",
		method: "GET",
		params: data,
	});
};
// 玩转景区详情
export const play_scenic_details = (data) => {
	return request({
		url: fhhUrl + "/play_scenic_content_api/",
		method: "GET",
		params: data,
	});
};