<template>
  <div style="padding-bottom:20px;height:100vh">
    <div class="flex flex-jc-start flex-ai-c goBack" @click="goBack">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
     <!-- 头部 -->
     <el-row :gutter="10" style="margin-bottom: 10px">
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="tabbar flex flex-c bar" v-for="(item, index) in tabbarList" :key="index" :class="tabIndex === index ? 'tabActive' : ''" @click.native="tabChange(index)">{{ item.name }}</el-col>
    </el-row>
    <el-carousel indicator-position="outside">
      <el-carousel-item v-for="(swiperItem, swiperIndex) in data.images" :key="swiperIndex">
        <img class="swiper-lazy" :src="base_url + swiperItem.image">
      </el-carousel-item>
    </el-carousel>
    <el-row :gutter="10" v-if="tabIndex === 0">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="tabbar flex flex-c flex-co" v-for="(infoItem, infoIndex) in data.items" :key="infoIndex" @click.native="checkDetails(infoItem, '1')">
        <div class="info">
          <el-image class="infoImg" :src="base_url + infoItem.image" fit="cover"></el-image>
          <div class="content">
            <div class="title">{{ infoItem.title }}</div>
            <div class="desc">{{ infoItem.desc }}</div>
          </div>
        </div>  
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="tabIndex === 1">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="tabbar flex flex-c flex-co">
        <div class="scenicDetails">
          <div v-html="scenicDetails"></div>
        </div>  
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="tabIndex === 2">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="tabbar flex flex-c flex-co" v-for="(playItem, playIndex) in playList" :key="playIndex" @click.native="checkDetails(playItem, '3')">
        <div class="info flex">
          <el-image class="spotImg" :src="base_url + playItem.image" fit="fill"></el-image>
          <div class="spoCcontent flex flex-jc-sb flex-co">
            <div class="spoCcontentText flex flex-jc-start">{{ playItem.title }}</div>
            <div class="flex flex-jc-fe"><el-button style="width:100px">查看详情</el-button></div>
          </div>
        </div>  
      </el-col>
    </el-row> 
    <div v-if="tabIndex === 3" >
      <el-radio-group v-model="scenicSpot" v-for="(item, index) in soptTabList" :key="index" @change="spotChange(item)">
      <el-radio-button :label="item.index">{{ item.name }}</el-radio-button>
    </el-radio-group>
    </div>
    <el-row :gutter="10" v-if="tabIndex === 3">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="tabbar flex flex-c flex-co" v-for="(spotItem, spotIndex) in spotList" :key="spotIndex" @click.native="checkDetails(spotItem, '2')">
        <div class="info flex">
          <el-image class="spotImg" :src="base_url + spotItem.image" fit="fill"></el-image>
          <div class="spoCcontent flex flex-jc-sb flex-co">
            <div class="spoCcontentText flex flex-jc-start">{{ spotItem.title }}</div>
            <div class="flex flex-jc-fe"><el-button style="width:100px">查看详情</el-button></div>
          </div>
        </div>  
      </el-col>
    </el-row> 
    <!-- 详情 -->
    <el-row :gutter="10" v-if="tabIndex === 4">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="tabbar flex flex-c flex-co">
        <div class="scenicDetails">
          <h4>{{ articleDetails.title }}</h4>
          <div>{{articleDetails.desc}}</div>
          <div v-html="articleDetails.text"></div>
        </div>  
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getOfficialWebsite, getFffhScenicSpotOverview, getFffScenicSpot, getFffhome_content, getActiveInfo, play_scenic_Info, play_scenic_details } from '@/api/index'
export default {
  name: 'mapImg',
  data() {
    return {
      tabbarList: [
        { name: '首页', index: 0 },
        { name: '景区概况', index: 1 },
        { name: '玩转景区', index: 2 },
        { name: '景区动态', index: 3 },
      ],
      tabIndex: 0,
      data: {},
      base_url: 'https://fhh-gw.q12301.com',
      scenicDetails: null,
      scenicSpot: 0,
      spotList: [],
      soptTabList: [
        { name: '动态新闻', index: 1 },
        { name: '景区资讯', index: 2 },
        { name: '媒体关注', index: 3 },
      ],
      spotHandleList: [],
      articleDetails: {},
      playList: []
    }
  },
  mounted() { 
    this.getIndexData()
    this.getSpotOverview()
    this.getScenicSpotList()
    this.getplay_scenic_Info()
  },
  methods: {
    getplay_scenic_Info() {
      play_scenic_Info().then(res => {
        this.playList = res.data.data
      })
    },
    checkDetails(item, index) {
      if(index === '1') {
        getFffhome_content({ sid: item.sid }).then(res => {
          this.articleDetails.title =res.data.content. title
          let newStr = res.data.content.content.replace(/src="([^"]+)"/g, 'src="https://fhh-gw.q12301.com$1"');
          this.articleDetails.text = newStr
          this.tabIndex = 4
        })
      } else if (index === '2') {
        getActiveInfo({ cid: item.sid }).then(res => {
          this.articleDetails.title =res.data.content.title
          let newStr = res.data.content.content.replace(/src="([^"]+)"/g, 'src="https://fhh-gw.q12301.com$1"');
          this.articleDetails.text = newStr
          this.tabIndex = 4
        })
      } else if (index === '3') {
        play_scenic_details({ sid: item.sid }).then(res => {
          this.articleDetails.title =res.data.content.title
          let newStr = res.data.content.content.replace(/src="([^"]+)"/g, 'src="https://fhh-gw.q12301.com$1"');
          this.articleDetails.text = newStr
          this.tabIndex = 4
        })
      }
    },
    spotChange(e) {
      var arr = [...this.spotHandleList]
      this.spotList = arr.filter(item => item.ty === e.name)
    },
    getScenicSpotList() {
      getFffScenicSpot().then(res => {
        this.spotHandleList = res.data.data
        var obj = {
          name: '动态新闻'
        }
        this.scenicSpot = 1
        this.spotChange(obj)
      })
    },
    getSpotOverview() {
      getFffhScenicSpotOverview().then(res => {
        // 使用正则表达式匹配并替换src属性
        let newStr = res.data.content.replace(/src="([^"]+)"/g, 'src="https://fhh-gw.q12301.com$1"');
        this.scenicDetails = newStr
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    getIndexData() {
      getOfficialWebsite().then(res => {
        this.data = res.data
      })
    },
    tabChange(index) {
      this.tabIndex = index
    }
  }  
}
</script>
<style lang="scss" scoped>
  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .goBack {
    padding: 1rem;
    width: 70px;
    cursor: pointer;
  }
  .tabbar {
    padding: 0.7rem;
    font-size: 1rem;
  }
  .tabActive {
    background: #409eff;
    color: #fff;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .swiper-lazy {
    width: 100%;
    height: 230px;
    background-repeat: no-repeat;
  }
  .el-carousel {
    height: 260px; /* 在大屏幕下的高度 */
  }
  ::v-deep .el-carousel__container {
    height: 230px; 
  }
  @media (min-width: 768px) {
    .el-carousel {
      height: 630px; /* 在大屏幕下的高度 */
    }
    ::v-deep .el-carousel__container {
      height: 600px; 
    }
    .swiper-lazy {
      height: 600px;
      background-repeat: no-repeat;
    }
  }
  .info {
    width: 90%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), -2px -2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }
  .infoImg {
    width: 100%;
    height: 15rem;
  }
  .desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* 设置要显示的行数 */
    text-overflow: ellipsis;
  }
  .content {
    padding: 0.8rem 1rem 1rem;
    .title {
      font-weight: 600;
      margin-bottom: 0.7rem;
    }
  }
  .scenicDetails {
    width: 90%;
  }
  .bar {
    font-size: 1rem;
  }
 
  ::v-deep .scenicDetails{
    img{
      width: 80% !important;
      height: 100% !important;
    }
  }
  .spotImg {
    width: 15rem;
    height: 10rem;
    .el-image__inner {
      width: 10rem;
    }
  }
  .spoCcontent {
    width: 50%;
    padding: 1rem;
  }
  .spoCcontentText {
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* 设置要显示的行数 */
    text-overflow: ellipsis;
  }
  @media (min-width: 768px) {
    .spotImg {
      width: 22%; /* 在大屏幕下的高度 */
      height: 13rem;
    }
    
  }
</style>