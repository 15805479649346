import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
import Vconsole from 'vconsole';
if (process.env.VUE_APP_SERVER_ENV == 'dev' || process.env.VUE_APP_SERVER_ENV == 'sit') {//使用环境变量进行判断
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}


Vue.use(Vant);
Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
