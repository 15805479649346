import axios from 'axios'
var session_id = ''
session_id = localStorage.getItem('session_id')
let headers = {}
if (session_id != '') {
    console.log(session_id)
    headers = {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'session-id': session_id
    }
} else {
    headers = {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'session-id': '4cf18f5b54cb7ae394591eb7a1cf8de5'
    }
}
const request = axios.create({
    baseURL:'https://q12301.com'
    // baseURL: 'http://192.168.2.47:8001'
})
request.defaults.timeout = 30000
request.defaults.headers = headers
request.interceptors.request.use((config)=>{
    return config
},(err)=>{
    return Promise.reject(err)
})

axios.interceptors.response.use((response)=>{
    console.log(response)
    return response
},(err)=>{
    return Promise.reject(err)
})

export default  request
